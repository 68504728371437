import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.storage.create();
  }

  // async init() {
  //   // If using, define drivers here: await this.storage.defineDriver(/*...*/);
  //   const storage = await this.storage.create();
  //   // eslint-disable-next-line no-underscore-dangle
  //   this._storage = storage;
  // }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    // eslint-disable-next-line no-underscore-dangle
    return this.storage.set(key, value);
  }

  public get(key: string) {
    // eslint-disable-next-line no-underscore-dangle
    return this.storage.get(key);
  }

  public getAuthCode() {
    return this.storage.get('authCode');
  }

  public getDeviceId() {
    return this.storage.get('deviceId');
  }

  public setAuthCode(authCode: string) {
    return this.storage.set('authCode', authCode);
  }

  public setDeviceId(deviceId: string) {
    return this.storage.set('deviceId', deviceId);
  }

  public setExpire(expire: string) {
    return this.storage.set('expire', expire);
  }

  public setUserType(userType: number) {
    return this.storage.set('userType', userType);
  }

  public getExpire() {
    return this.storage.get('expire');
  }

  public getUserType() {
    return this.storage.get('userType');
  }

  public clearData() {
    this.setAuthCode(null);
    this.setExpire(null);
    this.setUserType(null);
  }
}
