import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {StorageService} from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selectedLanguage: Subject<string> = new Subject<string>();

  constructor(private translate: TranslateService, private storage: StorageService) {
    this.translate.setDefaultLang('it');
    this.storage.get('language').then(language => {
      if( language ) {
        this.setSelectedLanguage(language);
      } else {
        this.setSelectedLanguage(this.translate.getDefaultLang());
      }
    });
  }

  setSelectedLanguage( language: string ) {
    this.selectedLanguage.next(language);
    this.translate.use(language);
    this.storage.set('language', language);
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }
}
