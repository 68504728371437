import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {StorageService} from '../storage/storage.service';
import {ApiService} from '../api/api.service';
import {MenuController, ModalController} from '@ionic/angular';
import {GenerateCodeComponent} from '../generate-code/generate-code.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() className: string;
  userType = 0;

  constructor(
    private storageService: StorageService,
    private apiService: ApiService,
    private modalCtrl: ModalController,
    private menuCtrl: MenuController
  ) { }

  async ngOnInit() {
    this.userType = await this.storageService.getUserType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title) {
      this.title = changes.title.currentValue;
    }
    if (changes.className) {
      this.className = changes.className.currentValue;
    }
  }

  async generateCode() {
    const modal = await this.modalCtrl.create({
      component: GenerateCodeComponent,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    // if (role === 'confirm') {
    //   this.message = `Hello, ${data}!`;
    // }
  }

  toggleMenu()
  {
    if( this.userType === 1 ) {
      this.menuCtrl.toggle();
    }
  }

}
