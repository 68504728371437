import {Component, OnInit} from '@angular/core';
import {Platform, ToastController} from '@ionic/angular';
import {StorageService} from './shared/storage/storage.service';
import {Md5} from 'ts-md5/dist/md5';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {filter, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from "./shared/api/api.service";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private storageService: StorageService,
    private apiService: ApiService,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private translate: TranslateService) {}

  async ngOnInit() {
    await this.initializeApp();
  }

  async initializeApp() {
    console.log("comp 1");
    await this.platform.ready();
    this.checkForUpdates();
    await this.initializeDeviceId();
  }

  private async promptUser() {
    const toast = await this.toastCtrl.create({
      message:  this.translate.instant('update_available_message'),
      position: 'bottom',
      buttons: [{ role: 'cancel', text: this.translate.instant('update_available_button') }]
    });
    await toast.present();
    toast
      .onDidDismiss()
      .then(() => this.swUpdate.activateUpdate())
      .then(() => window.location.reload());
  }

  private async checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))).subscribe(evt => {
        this.promptUser();
      });
      this.swUpdate.checkForUpdate();
    }

  }



  private async initializeDeviceId() {
    this.storageService.getDeviceId().then(async (deviceId: string) => {
      if ( deviceId === null ) {
        deviceId = this.generateDeviceId();
        await this.storageService.setDeviceId(deviceId);
      }
    });
  }

  private generateDeviceId(): string {
    const time = new Date();
    const md5 = new Md5();
    const milliseconds = time.getTime();
    return md5.appendStr(milliseconds.toString()).end();
  }
}
