// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-thumbnail {
  width: 100%;
}

.title {
  font-size: 18px;
  font-family: "Antonio", serif;
  color: #383c41;
}

.subtitle {
  font-size: 35px;
  font-weight: bold;
  font-family: "Antonio", serif;
  color: #32326f;
  margin-top: -8px;
  display: inline-block;
}

.title::after {
  content: "\\a";
  white-space: pre;
}

ion-toolbar {
  --border-width: 3px 0px 3px 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --min-height: 70px;
  --border-color: #32326f;
}

.footer-toolbar {
  font-size: 8px;
  margin-top: 3px;
  color: #fb0000;
}

ion-title {
  color: #435b71;
  font-weight: bold;
  --padding-top: 0px;
  --padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

ion-icon {
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/sponsor/sponsor.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;EACA,6BAAA;EACA,cAAA;AACF;;AACA;EACE,eAAA;EACA,iBAAA;EACA,6BAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;AAEF;;AAAA;EACE,aAAA;EACA,gBAAA;AAGF;;AAAA;EAEE,+BAAA;EACA,kBAAA;EACA,qBAAA;EACA,kBAAA;EACA,uBAAA;AAEF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,qBAAA;EACA,sBAAA;AACF","sourcesContent":["ion-thumbnail {\n  width: 100%;\n}\n\n.title {\n  font-size: 18px;\n  font-family: 'Antonio', serif;\n  color: #383c41;\n}\n.subtitle {\n  font-size: 35px;\n  font-weight: bold;\n  font-family: 'Antonio', serif;\n  color: #32326f;\n  margin-top: -8px;\n  display: inline-block;\n}\n.title::after {\n  content: \"\\a\";\n  white-space: pre;\n}\n\nion-toolbar {\n  //--background: linear-gradient(#ffffff, #9efa8b) center/2px 100%;\n  --border-width: 3px 0px 3px 0px;\n  --padding-top: 0px;\n  --padding-bottom: 0px;\n  --min-height: 70px;\n  --border-color: #32326f;\n  //border-top: solid #32326f;\n  //border-bottom: solid #32326f;\n}\n.footer-toolbar {\n  font-size: 8px;\n  margin-top: 3px;\n  color: #fb0000;\n}\n\nion-title {\n  color: #435b71;\n  font-weight: bold;\n  --padding-top: 0px;\n  --padding-bottom: 0px;\n  padding-left: 0px;\n  padding-right: 0px;\n}\n\nion-icon {\n  font-size: 10px;\n  display: inline-block;\n  vertical-align: middle;\n}\n\n//.sponsor-right {\n//  --padding-top: 7px\n//}\n//\n//.sponsor-left {\n//  --padding-end: 80px\n//}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
