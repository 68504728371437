import { Injectable } from '@angular/core';
import {StorageService} from '../storage/storage.service';
import { environment } from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MapModel} from '../../models/MapModel';
import {from, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Poi} from '../../models/Poi';
//import {fromPromise} from 'rxjs/internal-compatibility';
import {AuthCode} from '../../models/AuthCode';
import {Group} from "../../models/Group";
import {User} from "../../models/User";
import {MapLight} from "../../models/MapLight";
import {License} from "../../models/License";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private storageService: StorageService, private httpClient: HttpClient) { }

  getMaps(): Observable<MapModel[]> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.get<MapModel[]>(`${environment.baseUrl}api/maps`, {headers}))
    );
  }

  getPoi(): Observable<Poi[]> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.get<Poi[]>(`${environment.baseUrl}api/poi`, {headers}))
    );
    //   .pipe(
    //   catchError(this.handleError<Poi[]>('Error occured'))
    // );
  }

  addGroupWaypoint(groupId, waypointId): Observable<boolean> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.post<any>(`${environment.baseUrl}api/groups/${groupId}/waypoints`, {"waypoint_id": waypointId},{headers})
        .pipe(map(response => response.status === "OK"))
      )
    );
  }

  removeGroupWaypoint(groupId, waypointId): Observable<boolean> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.delete<any>(`${environment.baseUrl}api/groups/${groupId}/waypoints/${waypointId}`, {headers})
        .pipe(map(response => response.status === "OK"))
      )
    );
  }

  addGroupUser(groupId, userId): Observable<boolean> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.post<any>(`${environment.baseUrl}api/groups/${groupId}/users`, {"user_id": userId},{headers})
        .pipe(map(response => response.status === "OK"))
      )
    );
  }

  removeGroupUser(groupId, userId): Observable<boolean> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.delete<any>(`${environment.baseUrl}api/groups/${groupId}/users/${userId}`, {headers})
        .pipe(map(response => response === null))
      )
    );
  }

  getUsers(): Observable<User[]> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.get<User[]>(`${environment.baseUrl}api/users`, {headers}))
    );
  }

  getMapsLight(): Observable<MapLight[]> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.get<MapLight[]>(`${environment.baseUrl}api/maps-light`, {headers}))
    );
  }

  auth(deviceId, authCode, name): Observable<AuthCode> {
    const body = {
      authCode,
      name,
      deviceId
    };
    return this.httpClient.post<AuthCode>(`${environment.baseUrl}api/auth`, body,{headers:{'Content-Type': 'application/json'}} );
  }

  refreshAuth(): Observable<AuthCode> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.get<AuthCode>(`${environment.baseUrl}api/refresh-auth`, {headers}))
    );
  }

  getGroups(): Observable<Group[]> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.get<Group[]>(`${environment.baseUrl}api/groups`, {headers}))
    );
  }

  createGroup(groupName): Observable<Group> {
    const body = {
      name: groupName
    };
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.post<Group>(`${environment.baseUrl}api/groups`, body, {headers}))
    );
  }

  editGroup(groupId, groupName): Observable<Group> {
    const body = {
      name: groupName
    };
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.put<Group>(`${environment.baseUrl}api/groups/${groupId}`, body, {headers}))
    );
  }

  deleteGroup(groupId): Observable<boolean> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.delete<any>(`${environment.baseUrl}api/groups/${groupId}`, {headers})
        .pipe(map(response => response === null))
      )
    );
  }

  getLicenses(): Observable<License[]> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.get<License[]>(`${environment.baseUrl}api/licenses`, {headers}))
    );
  }

  createLicense(name, licenseNumber): Observable<License> {
    const body = {
      name: name,
      license_number: licenseNumber
    };
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.post<License>(`${environment.baseUrl}api/licenses`, body, {headers}))
    );
  }

  editLicense(licenseId, name, licenseNumber): Observable<License> {
    const body = {
      name: name,
      license_number: licenseNumber
    };
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.put<License>(`${environment.baseUrl}api/licenses/${licenseId}`, body, {headers}))
    );
  }

  deleteLicense(licenseId): Observable<boolean> {
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.delete<any>(`${environment.baseUrl}api/licenses/${licenseId}`, {headers})
        .pipe(map(response => response === null))
      )
    );
  }

  generateCode(expireDays): Observable<AuthCode> {
    const body = {
      expireDays
    };
    return from(this.getHeaders()).pipe(
      switchMap(headers => this.httpClient.post<AuthCode>(`${environment.baseUrl}api/generate-auth-code`, body, {headers}))
    );
  }

  private getHeaders(): Promise<HttpHeaders> {
    return Promise.all([
      this.storageService.getAuthCode(),
      this.storageService.getDeviceId()
    ]).then(values => new HttpHeaders({
        'auth-code': values[0],
        'device-id': values[1],
      // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }));
  }
}
