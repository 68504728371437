import { Component, OnInit } from '@angular/core';
import {MenuController} from "@ionic/angular";
import {StorageService} from "../storage/storage.service";

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {

  userType = 0;

  constructor(private menuCtrl: MenuController,
              private storageService: StorageService) { }

  async ngOnInit() {
    this.userType = await this.storageService.getUserType();
  }

  toggleMenu() {
    this.menuCtrl.toggle();
  }

}
