import {Component, OnInit} from '@angular/core';
import {LanguageService} from './language.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {

  public language: string;
  constructor(private languageService: LanguageService) {
    this.language = this.languageService.getCurrentLanguage();
  }

  changeLanguage() {
    this.languageService.setSelectedLanguage(this.language);
  }

  ngOnInit(): void {
    this.languageService.selectedLanguage.subscribe(language => {
      this.language = language;
    });
  }

}
