// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-language {
  --border-width: 0px;
}

.select-language::part(icon) {
  opacity: 0;
}

.select-language-it {
  background-image: url('it.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.select-language-en {
  background-image: url('gb.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.select-language-es {
  background-image: url('es.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/language/language.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AACA;EACE,UAAA;AAEF;;AACA;EACE,+BAAA;EACA,qBAAA;EACA,4BAAA;EACA,2BAAA;AAEF;;AACA;EACE,+BAAA;EACA,qBAAA;EACA,4BAAA;EACA,2BAAA;AAEF;;AACA;EACE,+BAAA;EACA,qBAAA;EACA,4BAAA;EACA,2BAAA;AAEF","sourcesContent":[".item-language {\n  --border-width: 0px;\n}\n.select-language::part(icon) {\n  opacity: 0;\n}\n\n.select-language-it {\n  background-image: url('../../../assets/images/flags/it.png');\n  background-size: 100%;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\n.select-language-en {\n  background-image: url('../../../assets/images/flags/gb.png');\n  background-size: 100%;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\n.select-language-es {\n  background-image: url('../../../assets/images/flags/es.png');\n  background-size: 100%;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
