import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss'],
})
export class SponsorComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  goToSponsorLanding()
  {
    window.open(encodeURI('https://youtu.be/OsHp49Zd8sI'), '_system', 'location=no');
  }

}
