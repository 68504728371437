import { Component } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ApiService} from '../api/api.service';
import {AuthCode} from '../../models/AuthCode';

@Component({
  selector: 'app-generate-code',
  templateUrl: './generate-code.component.html',
  styleUrls: ['./generate-code.component.scss'],
})
export class GenerateCodeComponent {

  expireDays = 7;
  authCode: AuthCode;

  constructor(private modalCtrl: ModalController, private apiService: ApiService) {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  generate() {
    this.authCode = null;
    this.apiService.generateCode(this.expireDays).subscribe(
      res => {
        this.authCode = res;
      },
      err => {}
    );
  }

  decrement() {
    if( this.expireDays > 1 ) {
      this.expireDays--;
    }
  }

  increment() {
    this.expireDays++;
  }

}
