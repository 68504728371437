import { Injectable } from '@angular/core';
import {ApiService} from "../shared/api/api.service";
import {StorageService} from "../shared/storage/storage.service";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService: ApiService, private storageService: StorageService) {}

  public async refreshToken() {
    console.log("service 1");
    const deviceId = await this.storageService.getDeviceId();
    const authCode = await this.storageService.getAuthCode();
    if ( deviceId !== null && authCode !== null ) {
      try {
        const authData = await lastValueFrom(this.apiService.refreshAuth());
        await this.storageService.setUserType(authData.type);
        await this.storageService.setAuthCode(authData.authCode);
        await this.storageService.setExpire(authData.expire);
      } catch (error) {
        this.storageService.clearData();
      }
    }
  }
}
