import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {StorageService} from '../shared/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private storageService: StorageService, private router: Router ) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const deviceId = await this.storageService.getDeviceId();
      const authCode = await this.storageService.getAuthCode();
      const expire = await this.storageService.getExpire();
      const dateExpire = new Date(expire);
      const dateNow = new Date();
      if (deviceId === null || authCode === null || dateExpire <= dateNow) {
        this.storageService.clearData();
        this.router.navigateByUrl('/auth');
        resolve(false);
      }
      resolve(true);
    });
  }
}
